<template>
  <div class="log-box">
    <!--操作-->
    <div class="operation-box">
      <DatePicker
        type="daterange"
        placement="bottom-end"
        placeholder="请选择日期"
        confirm
        clearable
        style="width: 230px"
        @on-change="datehandleChange"
        @on-ok="dateSure"
        @on-clear="datehandleClear"
      />
      <div class="search-size">
        <Input
          class="search-box"
          prefix="ios-search"
          v-model="searchValue"
          placeholder="请输入搜索关键字"
          clearable
          @on-enter="doSearch"
          @on-clear="handleClearSearch"
        ></Input>
        <div class="search-btn" @click="doSearch">搜索</div>
      </div>
      <div class="btn-box" @click="downloadClick">导出</div>
    </div>

    <!--表格-->
    <div class="table-size" ref="tablebox">
      <Table
        class="task-status-content"
        :columns="columns"
        :data="dataList"
        :height="tableHeight"
        border
      >
      </Table>
    </div>

    <!--分页-->
    <div class="page-size" v-if="pageParams.Total != 0">
      <Page
        prev-text="上一页"
        next-text="下一页"
        class-name="dt-pager"
        :show-total="true"
        :total="pageParams.Total"
        :current="pageParams.Page"
        :page-size="pageParams.Size"
        @on-change="changePageEvent"
      >
        共{{ Math.ceil(pageParams.Total / pageParams.Size) }}页 /{{
          pageParams.Total
        }}条记录
      </Page>
    </div>
  </div>
</template>

<script>
import { GetBehaviorList, DownloadBehaviorList } from "@/service";

export default {
  name: "SearchLog",
  data() {
    return {
      tableHeight: 200, //表格高度
      clientHeight: 600,

      columns: [
        {
          title: "产品",
          key: "app",
          align: "left",
          width: 110,
        },
        {
          title: "搜索时间",
          key: "createdAt",
          align: "left",
          width: 180,
        },
        {
          title: "搜索人用户名",
          key: "name",
          align: "left",
          width: 150,
        },
        {
          title: "搜索人手机号",
          key: "phone",
          align: "left",
          width: 150,
        },
        {
          title: "搜索模块",
          key: "module",
          align: "left",
          width: 120,
        },
        {
          title: "搜索内容",
          key: "searchInfo",
          align: "left",
          minWidth: 150,
        },
        {
          title: "有无搜索结果",
          key: "serverResponse",
          align: "center",
          width: 100,
        },
        {
          title: "实际选择内容",
          key: "realInfo",
          align: "left",
          minWidth: 150,
        },
        {
          title: "搜索人浏览器信息（名称&版本）",
          key: "browser",
          align: "left",
          minWidth: 200,
        },
        {
          title: "搜索人IP",
          key: "ip",
          align: "left",
          width: 150,
        },
      ],
      dataList: [],
      pageParams: {
        Total: 0,
        Size: 15,
        Page: 1,
      },

      searchValue: "",
      startDate: "",
      endDate: "",
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.pageParams.Total / this.pageParams.Size);
    },
  },
  mounted() {
    this.getTableData();
    this.changeTableHeight();
    this.resize();
    window.addEventListener("resize", this.changeTableHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.changeTableHeight);
  },
  methods: {
    changePageEvent(page) {
      this.pageParams.Page = page;
      this.getTableData();
    },

    //获取列表
    async getTableData() {
      let params = {
        page: this.pageParams.Page,
        pageSize: this.pageParams.Size,
        start: this.startDate,
        end: this.endDate,
        info: this.searchValue,
      };
      const res = await GetBehaviorList(params);
      if (res && res.code === 200) {
        this.pageParams.Total = res.data.pages.total || 0;
        this.dataList = res.data.items || [];
        //有无搜索结果
        for (let i in this.dataList) {
          if (!this.dataList[i].name) {
            this.dataList[i].name = "--";
          }
          if (!this.dataList[i].phone) {
            this.dataList[i].phone = "--";
          }
          if (!this.dataList[i].realInfo) {
            this.dataList[i].realInfo = "--";
          }
          this.dataList[i].serverResponse === true
            ? (this.dataList[i].serverResponse = "是")
            : (this.dataList[i].serverResponse = "否");
        }
      }
    },

    //搜索
    doSearch() {
      this.pageParams.Page = 1;
      this.getTableData();
    },
    //清空搜索
    handleClearSearch() {
      this.searchValue = "";
      this.pageParams.Page = 1;
      this.getTableData();
    },

    //日期筛选
    datehandleChange(date) {
      this.startDate = date[0] || "";
      this.endDate = date[1] || "";
    },
    dateSure() {
      this.pageParams.Page = 1;
      this.getTableData();
    },
    //日期筛选清空
    datehandleClear() {
      this.startDate = "";
      this.endDate = "";
      this.pageParams.Page = 1;
      this.getTableData();
    },

    //下载
    async downloadClick() {
      let params = {
        page: this.pageParams.Page,
        pageSize: this.pageParams.Size,
        start: this.startDate,
        end: this.endDate,
        info: this.searchValue,
      };
      let res = await DownloadBehaviorList(params);
      if (res && res.code === 200) {
        let url = this.getUrl() + res.data;
        //window.open(url);
        // 生成一个a标签
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        let path = String(res.data).split("/");
        link.download = path[path.length - 1];
        document.body.appendChild(link);
        link.click();

        this.$Message.success("导出成功");
      }
    },
    getUrl() {
      if (this.$config.proxyHost === "/") {
        return window.location.origin + "/";
      } else {
        return this.$config.proxyHost + "/";
      }
    },

    // 表格适配高度
    changeTableHeight() {
      // 动态判断表格高度
      this.$nextTick(() => {
        let tablebox = this.$refs.tablebox;
        if (tablebox) {
          this.tableHeight = tablebox.clientHeight;
        }
      });
    },
    // 改变尺寸
    resize() {
      this.clientHeight = document.documentElement.clientHeight;
    },
  },
};
</script>

<style lang="less" scoped>
.log-box {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 30px 2%;

  .operation-box {
    width: 100%;
    display: flex;
    align-items: center;

    .search-size {
      display: flex;
      align-items: center;
      margin: 0 0 0 15px;

      .search-box {
        width: 200px;
        height: 32px;
      }

      .search-btn {
        width: 60px;
        height: 32px;
        background: #3519fb;
        border-radius: 3px;
        color: #ffffff;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 0 0 -3px;
        position: relative;
        z-index: 1;
      }
    }

    .btn-box {
      width: 70px;
      height: 32px;
      background: #3519fb;
      border-radius: 3px;
      color: #ffffff;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 0 0 auto;
    }
  }

  .table-size {
    width: 100%;
    height: calc(100vh - 180px);
    margin: 20px 0;
  }

  .page-size {
    width: 100%;
    text-align: right;
  }
}
</style>
